import React from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import HTMLReactParser from "html-react-parser";
import "./LandingPage.scss";
import { Link } from "gatsby";
import { GetURL } from "../utils";

const NoBannerPage = (props) => {
    let titleText = "";
    if(props?.data?.Title) {
        titleText = props?.data?.Title.replace(props?.data?.Italic_Text, `<em>${props?.data?.Italic_Text}</em>`);
        titleText = HTMLReactParser(titleText)
    }
    return (
        <div className="landing-page">
            <section className="heading-block">
                <Container>                    
                    <Row className="justify-content-center">
                        <Col xl={11} xs={12} className="text-center">
                            <h1 className="h2 p-0">{titleText}</h1>
                            <div className="question-wrap w-xl-75 w-100 m-auto">{props?.data?.Description}</div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default NoBannerPage;