import React from 'react'
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col";

const RadioboxField = ({ fieldClass, name, value, required, grpmd, handlechange, checked, step, label, mainClass, options, labelClass }) => (
  <Form.Group as={Col} md={grpmd} controlId={"validation" + name} className={"form-wrap "+mainClass}>
      {label ? <Form.Label className={labelClass}>{label}{required ? '*' : ''}</Form.Label>: ''}
    <div key={name} className='radio-list'> 
      {options.map(val =>
        <Form.Check className={fieldClass} required={required} type="radio" label={val} name={name} value={val} id={val} onChange={handlechange} />
      )}
    </div>
  </Form.Group>

);


export default RadioboxField;