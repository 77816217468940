import React, { useEffect, useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import { isMobileOnly, isTablet, isDesktop } from "react-device-detect";
import HTMLReactParser from "html-react-parser";
import NewsCard from "../NewsCard/NewsCard";
import {PageLinks} from '../../components/common/constant';

const News = (props) => {
    const [filterClose, setFilterclose] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState("");
    const filterBtn = () => {
        setFilterclose(!filterClose);
    };

    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                news: newsAndVideos(where: {Publish: true}, sort: "Date:desc") {
                    _id
                    Category {
                        Title
                        _id
                        Sort
                    }
                    Url
                    Sort
                    Tile_Image {
                        url
                        alternativeText
                    }
                    Publish
                    Content_with_Image {
                        _id
                        Title
                        Content
                        Images {
                            alternativeText
                            url
                        }
                    }
                    Banner_Image {
                        alternativeText
                        url
                    }
                    Date
                    Sub_Category
                    Title
                    Video_Url
                    imagetransforms
                }
                newsCategories(where: {Publish: true}) {
                    _id
                    Title
                    Sort
                    Publish
                }
            }
        }
    `);

    const { news, newsCategories } = data.glstrapi;
    const [ category, setCategory ] = React.useState("all");
    const [catName, setCatName] = useState("")
    const filteredNews = category === "all" ? news : news.filter(c => c.Category?._id == category);

    let titleText = "";
    if(props?.data?.Title) {
        titleText = props?.data?.Title.replace(props?.data?.Italic_Text, `<span>${props?.data?.Italic_Text}</span>`)
        titleText = HTMLReactParser(titleText)
    }

    useEffect(() => {
        if(props && props.location && props.location.search) {
            let string = props?.location?.search?.split("?type=")[1]; 
            setCategory(newsCategories.filter(c => c.Title.toLowerCase() === string.toLowerCase())?.[0]?._id);
        }
    }, [])

    return ( 
        <div className="news-landing">
            <Container>
                <Row>
                    <Col xs="12">
                        <div className="heading-block">
                            <h1 className="h2">{titleText}</h1>
                            {props?.content?.Content && <p>{HTMLReactParser(props?.content?.Content)}</p>}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={filterClose ? "filter-open news-filter" : "news-filter"}>
                            <button className="filter-btn-value" onClick={filterBtn}>
                                {category === "all"
                                    ? 'All'
                                    : catName}
                                <i className="icon-caret-down"></i>
                            </button>
                            <div className="filter-btn-wrap">
                                <button 
                                    type="button" 
                                    className={category === "all" ? 'btn active': 'btn'}
                                    onClick={() => setCategory("all")}
                                >
                                    <span className="d-block">all</span>
                                </button>
                                {newsCategories?.filter(c => news.some(d => c._id === d.Category?._id)).map((each, i) => 
                                    <button 
                                        type="button" 
                                        className={(category === each._id) ? "btn active" : "btn"} key={i}
                                        onClick={() => {setCategory(each._id);setCatName(each.Title)}}
                                    >
                                        {each.Title}
                                    </button>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="news-section">
                    <Row>
                        {filteredNews && filteredNews.slice(0, 1).map(item => item && (
                            <Col xs="12" lg="6" className="first-child" key={item._id}>
                                <NewsCard 
                                    key={item?._id} 
                                    id={item?._id} 
                                    selectedVideo={selectedVideo}
                                    imagename="news-and-videos.Tile_Image.list"
                                    setSelectedVideo={(val) => setSelectedVideo(val)}
                                    newsCard={{
                                        newsTitle: item?.Title,
                                        newsTag: item?.Sub_Category,
                                        newsImg: item?.Tile_Image?.url,
                                        newsImgAlt: item?.Tile_Image?.alternativeText,
                                        newsImgTransforms: item?.imagetransforms,
                                        newsUrl: item?.Url+"/",
                                        newsVideo: true,
                                        videoID: item?.Video_Url,
                                        id: item?._id
                                    }} 
                                    video={item?.Video_Url}
                                    path={`/${PageLinks?.news_videos}/`}
                                />
                            </Col>
                        ))}
                        <Col xs="12" lg="6">
                            <Row className="news-row">
                                {isMobileOnly === true && 
                                    <React.Fragment>
                                        {filteredNews.slice(1, 5).map((item, index) => 
                                            <Col xs="12" md="6" key={item?._id}>
                                                <NewsCard 
                                                    key={item?._id} 
                                                    id={item?._id} 
                                                    imagename={`news-and-videos.Tile_Image.list_0`}
                                                    selectedVideo={selectedVideo}
                                                    setSelectedVideo={(val) => setSelectedVideo(val)}
                                                    newsCard={{
                                                        newsTitle: item?.Title,
                                                        newsTag: item?.Sub_Category,
                                                        newsImg: item?.Tile_Image?.url,
                                                        newsImgAlt: item?.Tile_Image?.alternativeText,
                                                        newsImgTransforms: item?.imagetransforms,
                                                        newsUrl: item?.Url+"/",
                                                        videoID: item?.Video_Url,
                                                        id: item?._id
                                                    }} 
                                                    video={item?.Video_Url}
                                                    path={`/${PageLinks?.news_videos}/`}
                                                />
                                            </Col>
                                        )}
                                    </React.Fragment>
                                }
                                {(isTablet === true) && 
                                    <React.Fragment> 
                                        {filteredNews.slice(1, 5).map((item, index) => 
                                            <Col xs="12" md="6" key={item._id}>
                                                <NewsCard 
                                                    key={item._id} 
                                                    id={item._id} 
                                                    imagename={`news-and-videos.Tile_Image.list_0`}
                                                    selectedVideo={selectedVideo}
                                                    setSelectedVideo={(val) => setSelectedVideo(val)}
                                                    newsCard={{
                                                        newsTitle: item?.Title,
                                                        newsTag: item?.Sub_Category,
                                                        newsImg: item?.Tile_Image?.url,
                                                        newsImgAlt: item?.Tile_Image?.alternativeText,
                                                        newsImgTransforms: item?.imagetransforms,
                                                        newsUrl: item?.Url+"/",
                                                        videoID: item?.Video_Url,
                                                        id: item?._id
                                                    }} 
                                                    video={item.Video_Url}
                                                    path={`/${PageLinks.news_videos}/`}
                                                />
                                            </Col>
                                        )} 
                                    </React.Fragment>
                                }
                                {isDesktop === true &&
                                    <React.Fragment> 
                                        {filteredNews.slice(1, 5).map((item, index) => 
                                            <Col xs="12" md="6" key={item._id}>
                                                <NewsCard 
                                                    key={item._id} 
                                                    id={item._id} 
                                                    selectedVideo={selectedVideo}
                                                    imagename={`news-and-videos.Tile_Image.list_1`}
                                                    setSelectedVideo={(val) => setSelectedVideo(val)}
                                                    newsCard={{
                                                        newsTitle: item?.Title,
                                                        newsTag: item?.Sub_Category,
                                                        newsImg: item?.Tile_Image?.url,
                                                        newsImgAlt: item?.Tile_Image?.alternativeText,
                                                        newsImgTransforms: item?.imagetransforms,
                                                        newsUrl: item?.Url+"/",
                                                        videoID: item?.Video_Url,
                                                        id: item?._id
                                                    }} 
                                                    video={item.Video_Url}
                                                    path={`/${PageLinks.news_videos}/`}
                                                />
                                            </Col>
                                        )} 
                                    </React.Fragment>
                                }
                            </Row>
                        </Col>
                        {filteredNews.slice(5).map((item, index) => (
                            <Col xs={12} md={6} xl={4} xxl={3} className="news-item" key={item._id}>
                                <NewsCard 
                                    key={item?._id} 
                                    id={item?._id} 
                                    selectedVideo={selectedVideo}
                                    imagename={`news-and-videos.Tile_Image.details_1`}
                                    setSelectedVideo={(val) => setSelectedVideo(val)}
                                    newsCard={{
                                        newsTitle: item?.Title,
                                        newsTag: item?.Sub_Category,
                                        newsImg: item?.Tile_Image?.url,
                                        newsImgAlt: item?.Tile_Image?.alternativeText,
                                        newsImgTransforms: item?.imagetransforms,
                                        newsUrl: item?.Url+"/",
                                        id: item?._id
                                    }} 
                                    video={item?.Video_Url}
                                    path={`/${PageLinks?.news_videos}/`}
                                />
                            </Col>
                        ))}
                    </Row>
                </div>
            </Container>
        </div> 
    )
}

export default News;
