import React from 'react';
import { Container, Row, Col} from "react-bootstrap";
import { Link } from "gatsby";
import ScrollAnimation from 'react-animate-on-scroll'; 
import "../AboutUs/AboutUs.scss";
import { GetURL } from '../utils';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const ServiceContact = (props) => {
    return (
        <React.Fragment>
            <section className="about-section">
                <Container>
                    <Row className="client-wrapper">
                        <Col lg={6} className="achievement-wrap">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="rtl-block">
                                    <span className="text">{props?.data[0]?.List_Title}</span>
                                    <div className="About-wrap">
                                        {props?.data[0]?.List_Component.map((item, k) => (
                                            <Link to={GetURL(item?.List_Link?._id)} className="story-wrapper d-flex about-story" key={k}>
                                                <div className="img-wrap">
                                                    <GatsbyImage
                                                        loading='lazy'
                                                        image={getImage(item?.Tile_Image?.url_sharp_sm)}
                                                        alt={item?.Tile_Image?.alternativeText ? item?.Tile_Image?.alternativeText : props?.data?.List_Title + "- tlc Estate Agents"} 
                                                    />
                                                </div>
                                                <div className="our-img d-flex align-items-center justify-content-between">
                                                    <span className="story">{item.Title}</span>
                                                    <i className="circle-arrow"></i>
                                                </div>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </section>   
        </React.Fragment>
    );
};

export default ServiceContact;
