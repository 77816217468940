import React from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import HTMLReactParser from "html-react-parser";
import "./LandingPage.scss";
import ServiceCard from "../ServiceCard/ServiceCard";
import { GetURL } from "../utils";

const ContactPage = (props) => {
    let titleText = "";
    if(props?.data?.Title) {
        titleText = props?.data?.Title.replace(props.data.Italic_Text, `<em>${props.data.Italic_Text}</em>`);
        titleText =HTMLReactParser(titleText)
    }
    return (
        <div className="landing-page">
            <section className="heading-block">
                <Container>
                    <Row>
                        <Col>
                        <h1 className="mb-2 px-0">{titleText}</h1>
                            {props?.data?.Description && <div className="question-wrap text-center">{HTMLReactParser(props?.data?.Description)}</div>}
                            <Row className="justify-content-center">
                                {props?.modules?.filter(c => Array.isArray(c.Links_with_Media_Data) && c.Links_with_Media_Data.length > 0).length > 0 &&
                                    <div className="service-list">
                                        {props?.modules?.filter(c => Array.isArray(c.Links_with_Media_Data) && c.Links_with_Media_Data.length > 0)[0]?.Links_with_Media_Data.map((each, k) => 
                                            <ServiceCard 
                                                key={k}
                                                serviceValue={{
                                                    serviceImg: each.Tile_Image,
                                                    serviceLink: each.Cta_Link ? GetURL(each.Cta_Link?._id) : "/",
                                                    serviceTitle: each.Cta_Label,
                                                    serviceContent: each.Video_Url
                                                }}
                                            />
                                        )}
                                    </div>
                                }
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default ContactPage;