import { Link } from "gatsby";
import HTMLReactParser from "html-react-parser";
import React from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Bookcard from "../BookCard/BookCard";
import "./StaticPage.scss";

const StaticPage = (props) => {
    return (
        <div className="static-page">
            <section className="heading-block bg-img-static" style={{ backgroundImage: `url(${props?.data?.Banner_Image?.url})` }}>
                <Container className="h-100">
                    <Row className="h-100 py-4 justify-content-between">
                        <Col xs={12} className="align-self-end">
                            <h1 className="mb-2 px-0">{props?.data?.Title}</h1>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="static-content">
                <Container>
                    <Row className="justify-content-between">
                        {props?.modules.map((module, i) => 
                            <React.Fragment key={i}>
                                {module.Content && <div className="col-xxxl-8 col-xl-7 col-12 static-content">
                                    {HTMLReactParser(module.Content)}
                                </div>}
                                {module.Book_Title &&<div className="col-xxxl-3 col-xl-4 col-12">
                                    <Bookcard
                                        bookcardTitle={module?.Book_Title}
                                        bookcardContent={module?.Description}
                                        bookcardBtn={module?.Cta_Label}
                                        bookcardLink={module.Cta_Link}
                                        column={true}
                                    />
                                </div>}
                            </React.Fragment>
                        )}
                    </Row>
                </Container>
            </section>
        </div>
    )
} 

export default StaticPage;