import React, { useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import './TeamCard.scss'
import { Link } from 'gatsby';
import ContactFormPage from '../forms/contact';
import { Modal } from 'react-bootstrap';
import ImageTransform from '../common/ggfx-client/module/components/image-transform';
import { isMobile, isDesktop } from 'react-device-detect';
import PlayVideo from "../PlayVideo/PlayVideo";

const TeamCard = (props) => {
    const teamCard = props.teamCard;
    const [isPlaying, setPlaying] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [form_name, setFormName] = useState("");
    const [form_to_email, setFormToEmail] = useState("");

    const showModal = (form_title, to_email) => {
        setShow(true)
        setFormName(form_title)
        setFormToEmail(to_email)
    }

    const ImageRenderList = ({item, imagename, id}) => {
        let processedImages = JSON.stringify({});
        if (teamCard?.imagetransforms?.Tile_Image_Transforms) {
            processedImages = teamCard?.imagetransforms?.Tile_Image_Transforms;
        }
        return (
            <ImageTransform
                imagesources={item?.memberPic}
                renderer="pic-src" imagename={imagename}
                attr={{ alt: item?.memberPicAlt ? item?.memberPicAlt : item.memberName, className: 'm-0' }}
                imagetransformresult={processedImages}
                lazyLoading={false}
                id={id}
            />
        )
    }

    return (
        <React.Fragment>
            <ScrollAnimation animateIn="fadeInUp">
                <div className="team-card position-relative">
                    <div className="team-pic">
                        <Link to={props.path+teamCard.memberLink}>

                            {/* <img src={teamCard.memberPic} alt={teamCard.memberName} /> */}
                            <ImageRenderList item={teamCard} imagename={"teams.Tile_Image.tile"} id={props?.id}/>
                        </Link>
                        {(props.video) && <button 
                            type="button" 
                            className="play-btn"
                            onClick={async () => {
                                await props.setSelectedVideo(props?.id);
                                setPlaying(true);
                            }}
                        >
                            <i className="video-icon"></i>
                        </button>}
                    </div>
                    <div className="team-contact">
                        <Link to={props.path+teamCard.memberLink}><h3 className="link">{teamCard.memberName}</h3></Link>
                        <ul >
                            <li>
                                <a href={`tel:${teamCard.memberNumber}`}><i className='icon-phone-light'></i> {teamCard.memberNumber}</a>
                            </li>
                            <li>
                                <a href="javascript:;" onClick={() => showModal(teamCard.memberName, teamCard.memberEmail)} className="cursor-pointer"> <i className='icon-mail'></i>Email </a>
                                {/* <a href={`mail:${teamCard.memberEmail}`}> <i className='icon-mail'></i>Email </a> */}
                            </li>
                            <li>
                                <Link to={props.path+teamCard.memberLink}><i className='icon-align-left'></i> {teamCard.memberBio}</Link>
                            </li>
                        </ul>
                    </div>
                    {(props?.video && (props?.selectedVideo == props?.id)) && <div className="full-video">
                        {isPlaying && 
                            <React.Fragment>
                                <div className="d-block">
                                    <PlayVideo
                                        isOpen={isPlaying}
                                        videoId={props?.video}
                                        stopPlay={setPlaying}
                                        width={'100%'} height={isDesktop ? '600px' : '100%'}
                                        fullscreen={isDesktop ? false : true}
                                        event={"Teams"}
                                    />
                                </div>
                            </React.Fragment>
                        }
                    </div>}
                </div>
            </ScrollAnimation>
            <Modal className="form-popup" onHide={() => setShow(false)} show={show} contentClassName="p-0" size="sm">
                <Modal.Body className="p-5">
                    <div className="card-title h5 text-center mb-3">Contact - {form_name}</div>
                    <button onClick={() => setShow(false)} type="button" className="close-modal">
                        <i className="icon-close-modal"></i>
                    </button> 
                    <div className="form-block career-form">
                        <ContactFormPage email={teamCard.memberEmail} formname={`Person Contact`}/>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default TeamCard;
