import React, { useEffect } from "react";
import { Container, Row, Col, Breadcrumb, Form, Button } from "react-bootstrap";
import Bookcard from "../BookCard/BookCard";
import "./Banner.scss";
import "./StaticPage.scss";
import "./VideoBanner.scss";
import { GetURL } from "../utils";
import { Link } from "gatsby";
import { navigate } from "@reach/router";
import ReapitBook from "../ReapitBook";
import $ from "jquery";
import HTMLReactParser from "html-react-parser";
import { getImage } from "gatsby-plugin-image";

const BgImagePage = (props) => {
    const [searchString, setSearchString] = React.useState("");
    const handleSubmit = (e) => {
        e.preventDefault();
        navigate(props.uri+"?q="+searchString);
        $('html, body').animate({
            scrollTop: $("#events").offset().top-130
        }, 0)
    }
    useEffect(() => {
        let string = props?.location?.search?.split("?q=")[1];
        setSearchString(string);
    }, []);

    let titleText = "";
    if(props?.data?.Title) {
        titleText = props?.data?.Title.replace(props?.data?.Italic_Text, `<em>${props?.data?.Italic_Text}</em>`);
        titleText =HTMLReactParser(titleText)
    }
    let descText = "";
    if(props?.data?.Description) {
        descText = props?.data?.Description;
    }
    let processedImages = JSON.stringify({});
    if (props?.data?.imagetransforms?.Banner_Image_Transforms) {
        processedImages = props?.data?.imagetransforms?.Banner_Image_Transforms;
    }

    return (
        <div className="static-page">
            {/* <ImageTransform 
                imagesources={props?.data?.Banner_Image?.url}
                renderer="bgImg" 
                imagename="articles.Banner_Image.e_banner"
                attr={{ alt: props?.data?.Banner_Image?.alternativeText, className: 'm-0' }}
                imagetransformresult={processedImages}
                id={props?.data?._id}
            > */}
                <section className="heading-block bg-img-banner banner" style={{ backgroundImage: `url(${getImage(props?.data?.Banner_Image?.url_sharp)?.images?.fallback?.src})` }}>
                    <Container className="h-100">
                        <Row className="h-100 py-4 justify-content-between">
                            <Col xs={12} className="align-self-end">
                                <h1 className="mb-2 px-0">{titleText}</h1>
                            </Col>
                            <div className="banner-form">
                                <p className={props.form ? "text-light" : "text-light"}>{HTMLReactParser(descText)}</p>
                                <div className="input-box">
                                    {props?.modules?.filter(c => c.List_of_Links != undefined)[0]?.List_of_Links.map((url, i) => 
                                        <React.Fragment key={i}>
                                            {url?.Link_Type == "Book_A_Valuation" && 
                                                <ReapitBook className='btn cursor-pointer' type="[&quot;sales&quot;,&quot;lettings&quot;]">{url.Sub_Menu_Label}</ReapitBook>
                                            }
                                            {url?.Link_Type == "Anchor" && 
                                                <a href="javascript:;" onClick={() => {
                                                    $('html, body').animate({
                                                        scrollTop: $(url?.Anchor_or_External).offset().top-130
                                                    }, 0)
                                                }} className='btn cursor-pointer'>{url.Sub_Menu_Label}</a>
                                            }
                                            {url?.Link_Type == "External" && 
                                                <a href={url?.Anchor_or_External} target="_blank" className='btn cursor-pointer'>{url.Sub_Menu_Label}</a>
                                            }
                                            {url?.Sub_Menu_Link && <Link to={GetURL(url?.Sub_Menu_Link?._id)} className='btn'>{url.Sub_Menu_Label}</Link>}
                                        </React.Fragment>
                                    )}
                                </div>
                                {props.form && 
                                    <Form className="d-flex flex-row mt-3 event-form" onSubmit={handleSubmit}>
                                        <Form.Control
                                            type="text"
                                            placeholder="Event name"
                                            className="me-3"
                                            value={searchString}
                                            onChange={(e) => setSearchString(e.target.value)}
                                        />
                                        <Button type="submit">
                                            Search
                                        </Button>
                                    </Form>
                                }
                            </div>
                        </Row>
                    </Container>
                </section>

            {/* </ImageTransform> */}
            {/* <section className= style={{ backgroundImage: `url(${props?.data?.Banner_Image?.url})` }}> */}
            {/* </section> */}
            {props?.modules?.filter(c => typeof c.Book_Title !== "undefined").length > 0 && <section className="static-content">
                <Container>
                    <Row className="justify-content-between">
                        {props?.modules.map((module, i) => 
                            <React.Fragment key={i}>
                                {module.Book_Title &&<Col xl={3} xs={12}>
                                    <Bookcard
                                        bookcardTitle={module?.Book_Title}
                                        bookcardContent={module?.Description}
                                        bookcardBtn={module?.Cta_Label}
                                        bookcardLink={module.Cta_Link}
                                        column={true}
                                    />
                                </Col>}
                            </React.Fragment>
                        )}
                    </Row>
                </Container>
            </section>}
        </div>
    )
} 

export default BgImagePage;