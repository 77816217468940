import React, { useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import TeamCard from "../TeamCard/TeamCard";
import HTMLReactParser from "html-react-parser";
import "./Teams.scss";
import {PageLinks} from '../../components/common/constant';

const Teams = (props) => {
    const [filterClose, setFilterclose] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState("");
    const filterBtn = () => {
        setFilterclose(!filterClose);
    };

    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                teams(sort:"Sort:asc",where: {Publish: true}) {
                    _id
                    Url
                    Name
                    Email
                    Designation
                    Category {
                        Title
                        _id
                        Sort
                    }
                    Bio
                    Mobile
                    Sort
                    Tile_Image {
                        url
                        alternativeText
                    }
                    Publish
                    Signature {
                        url
                        alternativeText
                    }
                    Video_Url
                    imagetransforms
                }
                teamCategories(where: {Publish: true}) {
                    _id
                    Title
                    Sort
                    Publish
                }
            }
        }
    `);

    const {teams, teamCategories} = data.glstrapi;
    const [category, setCategory] = React.useState("all");
    const [catName, setCatName] = useState("")
    const filteredTeams = category === "all" ? teams : teams.filter(c => c.Category?._id == category);
    console.log(category,"cat")
    let titleText = "";
    if(props?.data?.Title) {
        titleText = props?.data?.Title.replace(props?.data?.Italic_Text, `<span>${props?.data?.Italic_Text}</span>`)
        titleText = HTMLReactParser(titleText)
    }

    React.useEffect(() => {
        if(props?.location?.search) {
            let string = props?.location?.search?.split("?category=")[1];
            let category = teamCategories.filter(c => c.Title.split(" ").join("-").toLowerCase() == string)[0];
            setCategory(catName.Title);
        }
    }, [props?.location?.search]);

    React.useEffect(() => {
        console.log(props?.location?.search, "Category")
        if(props?.location?.search) {
            let string = props?.location?.search?.split("?category=")[1];
            let category = teamCategories.filter(c => c.Title.split(" ").join("-").toLowerCase() == string)[0];
            setCategory(category._id);
        }
    }, []);

    return ( 
        <div className="news-landing">
            <Container>
                <Row>
                    <Col xs="12">
                        <div className="heading-block">
                            <h1 className="h2">{titleText}</h1>
                            {props?.content?.Content && <p>{HTMLReactParser(props?.content?.Content)}</p>}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={filterClose ? "filter-open news-filter" : "news-filter"}>
                            <button className="filter-btn-value" onClick={filterBtn}>
                                {category === "all"
                                    ? 'All'
                                    : catName}
                                <i className="icon-caret-down"></i>
                            </button>
                            <div className="filter-btn-wrap">
                                <button 
                                    type="button" 
                                    className={category === "all" ? 'btn active': 'btn'}
                                    onClick={() => setCategory("all")}
                                >
                                    <span className="d-block">all</span>
                                </button>
                                {teamCategories?.filter(c => teams.some(d => c._id === d.Category?._id)).map((each, i) => 
                                    <button 
                                        type="button" 
                                        className={(category === each._id) ? "btn active" : "btn"} key={i}
                                        onClick={() => {setCategory(each._id);setCatName(each.Title)}}
                                    >
                                        {each.Title}
                                    </button>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="team-section">
                    <Row>
                        {filteredTeams.map(item => {
                            return(
                            <Col xs={12} md={6} lg={4} key={item._id} className="team-item">
                                <TeamCard 
                                    key={item._id} 
                                    id={item._id} 
                                    selectedVideo={selectedVideo}
                                    setSelectedVideo={(val) => setSelectedVideo(val)}
                                    teamCard={{
                                        memberPic: item.Tile_Image?.url,
                                        memberPicAlt: item.Tile_Image?.alternativeText,
                                        memberName: item.Name,
                                        memberNumber: item.Mobile,
                                        memberEmail: item.Email,
                                        memberBio: 'Bio',
                                        memberLink: item.Url+"/",
                                        blockVideo: item.Video_Url,
                                        imagetransforms: item.imagetransforms
                                    }}
                                    video={item.Video_Url}
                                    path={"/"+PageLinks.team_url+"/"}
                                />
                            </Col>
                        )}
                    )}
                    </Row>
                </div>
            </Container>
        </div> 
    )
}

export default Teams;
