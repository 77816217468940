import React, { useState } from "react"

import InputField from "./elements/input"
import TextAreaField from "./elements/textarea"
import ButtonField from "./elements/button"
import RadioboxField from "./elements/radio"
import HtmlBox from "./elements/html"

const FieldRender = ({ fields, setCheckbox }) => {
  const [fieldsVal, setFieldsVal] = useState(fields)

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  const getRadio = event => {
    event.target.value = event.target.value.trimStart()
    setCheckbox(event.target.value)
  }

  return (
    <>
      {fieldsVal.map((field, index) => {
        switch (field.type) {
          case "text":
          case "email":
          case "tel":
            return (
              <InputField {...field} key={index} handlechange={handlechange} />
            )
          case "message":
            return (
              <TextAreaField {...field} key={index} handlechange={handlechange} />
            )
          case "radio":
            return (
              <RadioboxField {...field} key={index} handlechange={getRadio} />
            )
          case "html":
            return ( <HtmlBox {...field} key={index} /> )
          case "submit":
            return ( <ButtonField {...field} key={index} /> )
          default:
            return null
        }
      })}
    </>
  )
}

export default FieldRender
