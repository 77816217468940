import React from 'react';
import { Link } from 'gatsby';
import './PropertyTypeCard.scss';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { GetURL } from '../utils';
import ReapitBook from '../ReapitBook';
import $ from "jquery";

const PropertyTypeCard = (props) => {
	return (
        <React.Fragment>
            {props?.data?.map((item) => (
                <div className='property-type_card' key={item._id}>
                    {item?.Link_Type == "Book_A_Valuation" && 
                        <ReapitBook type="[&quot;sales&quot;,&quot;lettings&quot;,&quot;manage&quot;]">
                            <div className="img-wrap img-zoom">
                                {<GatsbyImage
                                    image={getImage(item.Tile_Image?.url_sharp)}
                                    alt={item?.Tile_Image?.alternativeText ? item?.Tile_Image?.alternativeText : item.Cta_Label + " - tlc Estate Agents"}
                                />}
                            </div>
                            <div className="property-type_text">{item.Cta_Label}</div>
                        </ReapitBook>
                    }
                    {item?.Link_Type == "Anchor" && 
                        <a href="javascript:;" onClick={() => {
                            $('html, body').animate({
                                scrollTop: $(item?.Anchor_or_External).offset().top-130
                            }, 0)
                        }} className="cursor-pointer">
                            <div className="img-wrap img-zoom">
                                {<GatsbyImage
                                    image={getImage(item.Tile_Image?.url_sharp)}
                                    alt={item?.Tile_Image?.alternativeText ? item?.Tile_Image?.alternativeText : item.Cta_Label + " - tlc Estate Agents"}
                                />}
                            </div>
                            <div className="property-type_text">{item.Cta_Label}</div>
                        </a>
                    }
                    {item?.Link_Type == "External" && 
                        <a href={item?.Anchor_or_External} target="_blank" className="cursor-pointer">
                            <div className="img-wrap img-zoom">
                                {<GatsbyImage
                                    image={getImage(item.Tile_Image?.url_sharp)}
                                    alt={item?.Tile_Image?.alternativeText ? item?.Tile_Image?.alternativeText : item.Cta_Label + " - tlc Estate Agents"}
                                />}
                            </div>
                            <div className="property-type_text">{item.Cta_Label}</div>
                        </a>
                    }
                    {item?.Cta_Link && 
                        <Link to={GetURL(item?.Cta_Link?._id)} className="cursor-pointer">
                            <div className="img-wrap img-zoom">
                                {<GatsbyImage
                                    image={getImage(item.Tile_Image?.url_sharp)}
                                    alt={item?.Tile_Image?.alternativeText ? item?.Tile_Image?.alternativeText : item.Cta_Label + " - tlc Estate Agents"}
                                />}
                            </div>
                            <div className="property-type_text">{item.Cta_Label}</div>
                        </Link>
                    }
                </div>
            ))}
        </React.Fragment>
    )  
};

export default PropertyTypeCard;
