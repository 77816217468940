import React from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import HTMLReactParser from "html-react-parser";
import PropertyTypeCard from "../PropertyTypeCard/PropertyTypeCard";
import "./LandingPage.scss";
import { Link } from "gatsby";

const LandingPage = (props) => {
    let titleText = "";
    if(props?.data?.Title) {
        titleText = props?.data?.Title.replace(props?.data?.Italic_Text, `<em>${props?.data?.Italic_Text}</em>`);
        titleText = HTMLReactParser(titleText)
    }
    return (
        <div className="landing-page">
            <section className="heading-block">
                <Container>
                    <Row>
                        <Col>
                        <h1 className="h2">{titleText}</h1>
                            <div className="question-wrap">{props?.data?.Description}</div>
                            <div className="property-type_wrap">
                                {props?.modules?.filter(c => Array.isArray(c.Links_with_Media_Data) && c.Links_with_Media_Data.length > 0).length > 0 &&
                                    <PropertyTypeCard 
                                        data={props?.modules?.filter(c => Array.isArray(c.Links_with_Media_Data) && c.Links_with_Media_Data.length > 0)[0]?.Links_with_Media_Data}
                                    />
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default LandingPage;