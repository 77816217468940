import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss";
import "../scss/custom.scss";   
import { graphql } from "gatsby";
import Seo from "../components/seo";
import Modules from "../components/modules";
import _ from "lodash";

const DefaultTemplate = (props) => {
    const GQLPage = props?.data?.glstrapi?.article;
    const Page_Modules = props?.data?.glstrapi?.article?.Modules;
    const globalModule = props?.data?.glstrapi?.globalModule;
    const gardenSquares = props?.data?.glstrapi?.gardenSquares;
    const [show, setShow] = React.useState(false);

    React.useEffect(async() => {
        let form = await sessionStorage.getItem("form");
        if(form == null) {
            setTimeout(() => {
                setShow(true)
                sessionStorage.setItem("form", "true");
            }, 30000);
        }
        window.addEventListener('hashchange', function() {
            document.getElementById(window.location.hash.substring(1)).scrollIntoView({ behavior: "smooth" })
          }, false);

    }, []);

    return (
        <React.Fragment>
            <Seo
                title={GQLPage.Meta_Title} 
                description={GQLPage.Meta_Description}
                location={props.location}
            />
            <Modules
                page={GQLPage}
                modules={Page_Modules}
                {...(_.omit(props, ["data"]))}
                globalModule={globalModule}
                gardenSquares={gardenSquares}
            />
        </React.Fragment>
    );
};

export default DefaultTemplate;

export const pageQuery = graphql`
    query defaultQuery($id: ID!) {
        glstrapi {
            article(id: $id, publicationState: LIVE) {
                _id
                Pagename
                Alias
                Meta_Title
                Meta_Description
                Layout
                Title
                Italic_Text
                Description
                CSS_Class
                Banner_Image {
                    alternativeText
                    url
                    url_sharp {
                        childImageSharp {
                            gatsbyImageData(
                                formats: AUTO
                                width: 1400
                                height: 660
                                placeholder: BLURRED
                                quality: 50
                                transformOptions: {
                                    cropFocus: CENTER, fit: COVER
                                }
                            )
                        }
                    }
                }
                imagetransforms
                Video_Url
                Show_Valuation_Block
                Popular_Search
                Modules {
                    ... on GLSTRAPI_ComponentComponentsMenuLinks {
                        _id
                        List_of_Links {
                            Anchor_or_External
                            Link_Type
                            Sub_Menu_Label
                            Sub_Menu_Link {
                                _id
                                Label
                                URL
                                menu_burgers {
                                    Sub_Menus {
                                        Label
                                        Link {
                                            _id
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on GLSTRAPI_ComponentComponentsDynamicContents {
                        _id
                        Title
                        Content
                        Type
                        List_Title
                        Select_Team_Member {
                            Tile_Image {
                                alternativeText
                                url
                                url_sharp {
                                    childImageSharp {
                                        gatsbyImageData(
                                            formats: AUTO
                                            width: 110
                                            height: 104
                                            placeholder: BLURRED
                                            quality: 50
                                            transformOptions: {
                                                cropFocus: NORTH, fit: COVER
                                            }
                                        )
                                    }
                                }
                            }
                            Signature {
                                alternativeText
                                url
                            }
                            Designation
                            Mobile
                            Name
                            Email
                            Url
                            Video_Url
                            _id
                        }
                        List_Component {
                            _id
                            Title
                            Video_Url
                            Tile_Image {
                                alternativeText
                                url
                                url_sharp {
                                    childImageSharp {
                                        gatsbyImageData(
                                            formats: AUTO
                                            width: 440
                                            height: 500
                                            placeholder: BLURRED
                                            quality: 50
                                            transformOptions: {
                                                cropFocus: CENTER, fit: COVER
                                            }
                                        )
                                    }
                                }
                                url_sharp_sm: url_sharp {
                                    childImageSharp {
                                        gatsbyImageData(
                                            formats: AUTO
                                            width: 88
                                            height: 74
                                            placeholder: BLURRED
                                            quality: 50
                                            transformOptions: {
                                                cropFocus: NORTH, fit: COVER
                                            }
                                        )
                                    }
                                }
                            }
                            Sort
                            Publish
                            List_Link {
                                Label
                                _id
                                URL
                            }
                            List_Label
                            Content
                        }
                    }
                    ... on GLSTRAPI_ComponentComponentsAboutComponent {
                        id
                        _id
                        Title
                        Sm_Title
                        Italic_Text
                        Content
                        Data_Type
                        Cta_Label
                        Show_Review
                        Short_Video {
                            url
                        }
                        Tile_Image {
                            alternativeText
                            url
                            url_sharp {
                                childImageSharp {
                                    gatsbyImageData(
                                        formats: AUTO
                                        width: 1360
                                        height: 1040
                                        placeholder: BLURRED
                                        quality: 100
                                        transformOptions: {
                                            cropFocus: CENTER, fit: COVER
                                        }
                                    )
                                }
                            }
                        }
                        Select_Team {
                            Tile_Image {
                                alternativeText
                                url
                                url_sharp {
                                    childImageSharp {
                                        gatsbyImageData(
                                            formats: AUTO
                                            width: 110
                                            height: 104
                                            placeholder: BLURRED
                                            quality: 50
                                            transformOptions: {
                                                cropFocus: NORTH, fit: COVER
                                            }
                                        )
                                    }
                                }
                            }
                            Signature {
                                alternativeText
                                url
                            }
                            Designation
                            Mobile
                            Name
                            Email
                            Category {
                                Label: Title
                                _id
                            }
                            Url
                            Video_Url
                            _id
                        }
                        Video_Url
                        About_Cta_Link {
                            _id
                            URL
                        }
                    }
                    ... on GLSTRAPI_ComponentComponentsSelectCollection {
                        Choose_Collecttion
                        Title
                        _id
                    }
                    ... on GLSTRAPI_ComponentComponentsMap {
                        Latitude
                        Longitude
                        Iframe_Src
                        Show
                        _id
                    }
                    ... on GLSTRAPI_ComponentComponentsLinksWithImages {
                        _id
                        Links_with_Media_Data {
                            _id
                            Cta_Label
                            Cta_Link {
                                _id
                                URL
                            }
                            Anchor_or_External
                            Link_Type
                            Video_Url
                            Tile_Image {
                                alternativeText
                                url
                                url_sharp {
                                    childImageSharp {
                                        gatsbyImageData(
                                            formats: AUTO
                                            width: 480
                                            placeholder: BLURRED
                                            quality: 100
                                            transformOptions: {
                                                cropFocus: CENTER, fit: COVER
                                            }
                                        )
                                    }
                                }
                            }
                            Sort
                            Publish
                        }
                    }
                    ... on GLSTRAPI_ComponentComponentsAccordian {
                        _id
                        Sm_Title
                        Title
                        Content
                        Anchor_Id
                        Layout
                        Acc_Image: Tile_Image {
                            alternativeText
                            url
                            url_sharp {
                                childImageSharp {
                                    gatsbyImageData(
                                        formats: AUTO
                                        width: 560
                                        height: 800
                                        placeholder: BLURRED
                                        quality: 50
                                        transformOptions: {
                                            cropFocus: CENTER, fit: COVER
                                        }
                                    )
                                }
                            }
                        }
                        Accordian_List {
                            _id
                            Title
                            Content
                        }
                    }
                    ... on GLSTRAPI_ComponentComponentsBookAAppointment {
                        _id
                        Book_Title
                        Description
                        Cta_Label
                        Cta_Link {
                            URL
                            _id
                        }
                    }
                    ... on GLSTRAPI_ComponentComponentsLetsWorkTogether {
                        _id
                        Lets_Work_Title: Title
                        Sm_Title
                        Cta_1_Label
                        Cta_2_Label
                        Cta_1_Link {
                            URL
                            _id
                        }
                        Cta_2_Link {
                            URL
                            _id
                        }
                    }
                    ... on GLSTRAPI_ComponentComponentsMultipleImages {
                        Fluid_Images {
                            alternativeText
                            url
                            url_sharp {
                                childImageSharp {
                                    gatsbyImageData (
                                        formats: AUTO
                                        width: 700
                                        height: 600
                                        placeholder: BLURRED
                                        quality: 75
                                        transformOptions: {
                                            cropFocus: CENTER, fit: COVER
                                        }
                                    )
                                }
                            }
                        }
                    }
                    ... on GLSTRAPI_ComponentComponentsStaticContent {
                        Title
                        Content
                        Classname
                    }
                    ... on GLSTRAPI_ComponentComponentsRichTextContent {
                        Sm_Title
                        Title
                        Content
                        Choose_Collection_Type
                    }
                    ... on GLSTRAPI_ComponentComponentsChooseCustomerStories {
                        id
                        Title
                        Customer_Stories (sort: "Sort") {
                            _id
                            Title
                            Video_Url
                            Tile_Image {
                                url
                                alternativeText
                            }
                            Sort
                            Publish
                            Date
                            imagetransforms
                        }
                    }
                    ... on GLSTRAPI_ComponentComponentsContactForm {
                        id
                        Email
                        Address
                        Telephone
                        Options
                        To_Email
                        To_Bcc
                        Select_Member {
                            Name
                            Email
                            Designation
                            Mobile
                            Tile_Image {
                                url
                                alternativeText
                            }
                        }
                    }
                    ... on GLSTRAPI_ComponentComponentsSelectEvent {
                        id
                        Choose_Event {
                            Address
                            Date
                            Description
                            EventId
                            From
                            Title
                            To
                            Url
                            Video_Url
                            Tile_Image {
                                url
                                alternativeText
                                url_sharp {
                                    childImageSharp {
                                        gatsbyImageData (
                                            formats: AUTO
                                            width: 700
                                            height: 600
                                            placeholder: BLURRED
                                            quality: 75
                                            transformOptions: {
                                                cropFocus: CENTER, fit: COVER
                                            }
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
            globalModule {
                Book_A_Appointment {
                    _id
                    Book_Title
                    Description
                    Cta_Label
                    Cta_Link {
                        _id
                        URL
                    }
                }
                Lets_Work_Together {
                    _id
                    Title
                    Sm_Title
                    Cta_1_Label
                    Cta_2_Label
                    Cta_1_Link {
                        URL
                        _id
                    }
                    Cta_2_Link {
                        URL
                        _id
                    }
                    Background_Image {
                        url
                        alternativeText
                        url_sharp {
                            childImageSharp {
                                gatsbyImageData(
                                    formats: AUTO
                                    width: 1800
                                    placeholder: BLURRED
                                    quality: 50
                                    transformOptions: {
                                        cropFocus: CENTER, fit: COVER
                                    }
                                )
                            }
                        }
                    }
                }
                News_Subscribe_Form {
                    Title
                    Description
                    To_Email
                    To_Bcc
                    Card_Image {
                        alternativeText
                        url
                    }
                }
                Events_Subscribe_Form {
                    Title
                    Description
                    To_Email
                    To_Bcc
                    Card_Image {
                        alternativeText
                        url
                    }
                }
            }
            gardenSquares {
                lat: Latitude
                lng: Longitude
                image: Banner_Image {
                    url
                    alternativeText
                }
                Title
                Url
            }
        }
    }
`
