import React, { useState } from 'react';
import { Container, Row, Col, Breadcrumb, Modal } from 'react-bootstrap';
import { Link } from "gatsby";
import {Helmet } from "react-helmet"
import "./Contact.scss";
import ScrollAnimation from 'react-animate-on-scroll';
import HTMLReactParser from 'html-react-parser';
import ContactFormPage from '../forms/contact';
import OfferFormPage from '../forms/offer-form';
import ServiceContact from "../ContactUs/ServicesContact"

const ContactUs = (props) => {
    const [form_name, setFormName] = useState("");
    const [form_to_email, setFormToEmail] = useState("");
    const [show, setShow] = useState(false);

    const showModal = (form_title, to_email) => {
        setShow(true)
        setFormName(form_title)
        setFormToEmail(to_email)
    }

    let titleText = "";
    if(props?.data?.Title) {
        titleText = props?.data?.Title.replace(props?.data?.Italic_Text, `<span>${props?.data?.Italic_Text}</span>`);
        titleText = HTMLReactParser(titleText)
    }
   
    var servicedata = props.modules.filter(c => c.Content === null)
    //console.log("servicedata",servicedata)
    return (
        <React.Fragment>
            <Helmet>
                <script type="application/ld+json">{`{
                    "@context": "https://schema.org",
                    "@type": "Product",
                    "name": "${props?.data?.Meta_Title}",
                    "description": "${props?.data?.Meta_Description}",
                    "brand": {
                        "@type": "Brand",
                        "name": "Award-Winning Estate and Letting Agents in Kensington and Chelsea | tlc"
                    },
                    "aggregateRating": {
                        "@type": "AggregateRating",
                        "ratingValue": "4.9",
                        "reviewCount": "505"
                    },
                    "review": [
                        {
                            "@type": "Review",
                            "author": {
                                "@type": "Person",
                                "name": "Gab Mss"
                            },
                            "datePublished": "2023-05-30",
                            "reviewBody": "Great team who provide an amazing service! I managed to get a unit quickly and the whole process was effortless due to TLC. Highly recommend!",
                            "name": "Great Service",
                            "reviewRating": {
                                "@type": "Rating",
                                "ratingValue": "5"
                            }
                        },
                        {
                            "@type": "Review",
                            "author": {
                                "@type": "Person",
                                "name": "John Smith"
                            },
                            "datePublished": "2023-07-23",
                            "reviewBody": "Dealing with TLC has been an amazing experience. I could not be more pleased with the exceptional service I received from all departments and the Block Management Team.
                        All in all 10/10",
                            "name": "Highly Professional",
                            "reviewRating": {
                                "@type": "Rating",
                                "ratingValue": "5"
                            }
                        }
                    ]                  
                }`}
                </script>    
            </Helmet>
            <section className='contact-section'>
                <Container>
                    <ScrollAnimation animateIn='fadeInUp'>
                        <Row>                            
                            <Col md={12}>
                                <div className='heading'>
                                <h1 className="mb-2 px-0">{titleText}</h1>
                                    {props?.modules?.filter(c => c.Content !== "").map((module, k) => 
                                        <React.Fragment key={k}>
                                            {module.Content && HTMLReactParser(module?.Content)}
                                        </React.Fragment>
                                    )}
                                </div>
                            </Col>
                           
                        </Row>
                        <Row className='contact-wrap'>
                            <Col md={12} lg={8}>
                                <div className='contact-wrapper'>
                                {props.data.Pagename === "Contact Offer Valuation" ?
                                    <OfferFormPage
                                        options={props?.modules?.filter(c => c.Options).map(c => c.Options.split("\n")).flat()}
                                        formname={props?.data?.Pagename}
                                        email={props?.modules?.filter(c => c.To_Email != null)[0]?.To_Email}
                                    />
                                :
                                    <ContactFormPage
                                        options={props?.modules?.filter(c => c.Options).map(c => c.Options.split("\n")).flat()}
                                        formname={props?.data?.Pagename}
                                        email={props?.modules?.filter(c => c.Select_Member != null)[0]?.Select_Member?.Email}
                                    />    
                                }
                                </div>
                            </Col>
                            <Col md={12} lg={4}>
                                {props?.modules?.filter(c => c.Select_Member != null).map((module, k) =>
                                    <div className='info-wrapper'>
                                        <div className='director d-flex'>
                                            <div className='sales-img'>
                                                <picture>
                                                    <img src={module?.Select_Member?.Tile_Image?.url} alt={module?.Select_Member?.Name + " " + module?.Select_Member?.Designation - "tlc Estate Agents"} />
                                                </picture>
                                            </div>
                                            <div className='info' key={k}>
                                                <h2>{module?.Select_Member?.Name}</h2>
                                                <span className='sm-text'>{module?.Select_Member?.Designation}</span>
                                                <div className='contact'>
                                                    <Link href={`tel:${module?.Select_Member?.Mobile}`} className='tel'>
                                                        <i className='icon-phone-grey'></i>
                                                        {module?.Select_Member?.Mobile}</Link>
                                                    <a href="javascript:;" onClick={() => showModal(module?.Select_Member?.Name, module?.Select_Member?.Email)} className='tel'>
                                                        <i className='icon-mail'></i>
                                                        Email
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='call-us'>
                                            <h2>Telephone</h2>
                                            <Link href={`tel:${module?.Telephone}`} className='tel'>{module?.Telephone}</Link>
                                        </div>
                                        <div className='call-us mail-us'>
                                            <h2>Email Address</h2>
                                            <a href="javascript:;" onClick={() => showModal(module?.Name, module?.Email)} className='mail'>{module?.Email}</a>
                                        </div>
                                        <div className='address-details'>
                                            <h2>Address</h2>
                                            <address className="our-address">
                                                {module?.Address}
                                            </address>

                                        </div>
                                        <div>
                                          <ServiceContact data={servicedata}/>
                                        </div>
                                    </div>
                                )}
                                {props.data.Pagename === "Contact Offer Valuation" && props?.modules?.filter(c => c.Select_Member === null).map((module, k) =>
                                    <div className='info-wrapper'>
                                        <div className='director d-flex'>
                                            <div className='sales-img'>
                                                <picture>
                                                    <img src={"https://ggfx-tlclondon.s3.eu-west-2.amazonaws.com/i.dev/oie_1613215_Ces_UT_27q_e86ba23d11.jpg"} alt={"tlc Estate Agents"} />
                                                </picture>
                                            </div>
                                        </div>
                                        <div className='call-us'>
                                            <h2>Telephone</h2>
                                            <Link href={`tel:${module?.Telephone}`} className='tel'>{`${module?.Telephone} (2)`}</Link>
                                        </div>
                                        <div className='call-us mail-us'>
                                            <h2>Email Address</h2>
                                            <a href={`mailto:${module?.Email}`} className='mail'>{module?.Email}</a>
                                        </div>
                                        <div className='address-details'>
                                            <h2>Address</h2>
                                            <address className="our-address">{module?.Address}</address>
                                        </div>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </ScrollAnimation>
                </Container>
            </section>
            <Modal className="form-popup" onHide={() => setShow(false)} show={show} contentClassName="p-0" size="sm">
                <Modal.Body className="p-5">
                    <div className="card-title h5 text-center mb-3">Contact - {form_name}</div>
                    <button onClick={() => setShow(false)} type="button" className="close-modal">
                        <i className="icon-close-modal"></i>
                    </button> 
                    <div className="form-block career-form">
                        <ContactFormPage email={form_to_email} formname={`Person Contact`}/>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>

    )

}
export default ContactUs;
