import React, { useState } from 'react';
import { Link } from "gatsby";
import './ServiceCard.scss';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import ServiceImg from "../../images/blank-img.png";

const ServiceCard = (props) => {
    const serviceValue = props.serviceValue;
    return (
        <React.Fragment>            
            <Link to={serviceValue.serviceLink} className='service-wrapper'>
                <div className="service-block">
                    {serviceValue.serviceImg ? 
                        typeof serviceValue.serviceImg?.url_sharp !== "undefined" ? 
                            <div className='service-img'>
                                <GatsbyImage
                                    image={getImage(serviceValue.serviceImg?.url_sharp)}
                                    alt="serviceImg"
                                    className="h-100"
                                    imgClassName="h-100"
                                />  
                            </div>
                        :
                        <div className='service-img service-contact p-0'>
                            <img
                                src={serviceValue.serviceImg}
                                alt="serviceImg"
                                className="object-fc"
                            />
                        </div>
                        : 
                        <div className='service-img'>
                            <img src={ServiceImg} alt="img" />
                        </div>
                    }
                    <div className="service-contact">
                        <h3>{serviceValue.serviceTitle}</h3>
                        <span className='sm-info'>{serviceValue.serviceContent}</span>
                    </div>
                </div>
            </Link>
        </React.Fragment>
    );

}

export default ServiceCard;
