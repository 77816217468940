import React from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import HTMLReactParser from "html-react-parser";
import "./LandingPage.scss";
import CustomerStories from "../CustomerStories/CustomerStories";
import Google from "../../images/google-review.png";
import { Link } from "gatsby";

const ReviewBanner = (props) => {
    let titleText = "";
    if(props?.data?.Title) {
        titleText = props?.data?.Title.replace(props?.data?.Italic_Text, `<em>${props?.data?.Italic_Text}</em>`);
        titleText = HTMLReactParser(titleText)
    }
    return (
        <div className="landing-page review-banner">
            <section className="heading-block">
                <Container>
                    <Row>
                        <Col>
                            <h1 className="h2">{titleText}</h1>
                            <div className="question-wrap">{props?.data?.Description}</div>
                            {/* <div className="rating-wrap mob-hide">
                                <img src={Google} alt="property-img" />
                                <span className="rating">
                                    Rated <span className="review"> 4.9 out of 5 </span>from our valued customers.
                                </span>
                            </div> */}
                            <div className="property-type_wrap customer-reviews mt-5">
                                <CustomerStories
                                    data={props.module}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default ReviewBanner;