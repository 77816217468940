import React, { useState, useEffect } from "react"
import { Form, Row } from "react-bootstrap"

import * as qs from "query-string"
import _ from "lodash"

import { postFormData } from "./api/Api"
import FieldRender from './renderFields'
import fields from './form/offer.json'
import ReCaptchaBox from "./elements/recaptcha"

function MySimpleForm(props) {

    const [validated, setValidated] = useState(false);
    const [showerror, setShowerror] = useState(false);
    const [showthankyou, setThankyou] = useState(false);
    const [checkbox, setCheckbox] = useState("No");
    const [formvalues, setFormvalues] = useState("");
    const [showCapcha, setShowCaptch] = useState(true);
    const [token, setToken] = useState("");
    const myRef = React.createRef();
    const recaptchaRef = React.createRef();

    useEffect(() => {
        if (token !== '') {
            const processFromData = async () => {
                let formData = new FormData();
                formData.append('data', JSON.stringify(formvalues));
                postFormData(formData).then(async apiRes => {
                    // lets send mail
                    // await recaptchaRef.current.reset();
                    await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
                        method: `POST`,
                        mode: "no-cors",
                        headers: {
                          'Access-Control-Allow-Origin': '*',
                          "Content-Type": "application/x-www-form-urlencoded",
                        },
                        body: qs.stringify(formvalues),
                    })
                });

                formvalues['g-recaptcha-response'] = token;

                // tracking event
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'formSubmit',
                    'formType': `form-${fields[0].event_tracking}`,
                    'formId': `form-${fields[0].event_tracking}`,
                    'formName': props.formname ? props.formname : fields[0].formname,
                    'formLabel': props.formname ? props.formname : fields[0].formname
                });

                setShowerror(false);
                setThankyou(true);
                setShowCaptch(false)
                setTimeout(() => {
                    setThankyou(false);
                    setShowCaptch(true)
                }, 3000);
                myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })

            }
            processFromData();
        }
    }, [token]);

    const handleonVerify = token => {
        setToken(token);
    };

    const handleSubmit = event => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setShowerror(true);
            setValidated(true);
            setThankyou(false);
            myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        } else {
            const formsdata = (event.target);
            const json = {};
            Object.keys(formsdata).map(key => (
                json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
            ))
            json['email_temp_user'] = fields[0].email_temp_user;
            json['email_temp_admin'] = fields[0].email_temp_admin;
            json['formname'] = fields[0].formname;
            json['g-recaptcha-response'] = token;
            json['email_subject_user'] = fields[0].email_subject_user;
            json['email_subject_admin'] = fields[0].email_subject_admin;

            if(props.formname) {
                json["formname"] = props.formname;
                json["form_name"] = props.formname;
            }

            json["name"] = `${json["first_name"]} ${json["last_name"]}`;
            json["guarantor"] = checkbox;
            json['extra'] = _.omit(json, ["formname", "name", "email", "telephone"])

            setFormvalues(json);
            recaptchaRef.current.execute();
            setValidated(false);
            // reset form
            const form = event.target
            form.reset();
        }
    };

    return (
        <div className="form-wrapper">
            <div ref={myRef} />

            {(showerror || showthankyou) &&
                <div className={`alert mb-4 text-center alert${showerror ? "-danger" : showthankyou && "-success"}`}>
                    <p className="mb-0">{showerror ? fields[0]?.error_text : showthankyou && fields[0].success_text}</p>
                </div>
            }
            
            <Form name={fields[0].formname} id="contact-form" noValidate validated={validated} onSubmit={handleSubmit}>
                <input type="hidden" name="form_name" value={fields[0].formname} />
                <input type="hidden" name="form_type" value={fields[0].form_type} />
                <input type="hidden" name="to_email_id" value={props?.email ? props?.email : `${process.env.GATSBY_MAIL_TO_OFFER}`} />
                <input type="hidden" name="bot-field" />
                <Row >
                    <FieldRender setCheckbox={setCheckbox} fields={fields} />
                    {showCapcha &&
                        <ReCaptchaBox key={"captcha"} fieldClass={"py-2"} captRef={recaptchaRef} handleonVerify={handleonVerify} />
                    }
                </Row>
            </Form>
        </div>
    );
}

const OfferFormPage = (props) => (
    <MySimpleForm {...props}/>
)

export default OfferFormPage