import React, { useState, useEffect} from "react";
import { Container } from 'react-bootstrap';
import "../NewsEvents/NewsEvents.scss";
import NewsCard from '../NewsCard/NewsCard';
import Slider from "react-slick";

const CustomerStories = (props) => {

    var settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <div className="event-section" id="customer-stories">
            <Container>
                <div className='event-slider'>
                    <span className='sub-title'>{props?.data?.Title}</span>
                    <Slider className="slick-arrow" {...settings}>
                        {props?.data?.Customer_Stories?.map(each => (
                            <NewsCard 
                                key={each._id} 
                                imagename="customer-stories.Tile_Image.banner"
                                newsCard={{
                                    newsImg: each.Tile_Image?.url,
                                    newsImgTransforms: each.imagetransforms,
                                    newsTitle: each.Title,
                                    newsTag: each.Date,
                                    videoID: each.Video_Url,
                                    newsVideo: true,
                                    id: each._id
                                }} 
                            />
                        ))}
                    </Slider>
                </div>
            </Container>
        </div>
    );

}

export default CustomerStories;
